.c-receivable_pdf-download-button {
  @apply relative;
  @apply flex items-center justify-center;
  @apply px-3 py-2;
  @apply bg-gray-200;
  @apply rounded-full;
  @apply text-2xl text-gray-600;

  &__arrow {
    @apply absolute;
    @apply text-sm;

    top: 1.1rem;
    left: 1.4rem;
  }

  &:hover {
    @apply bg-gray-300;
  }
}
