.c-dashboard_chart {
  @apply w-full;
  @apply flex flex-col;
  @apply pt-2 pb-10 px-4;

  &__wrapper {
    @apply relative;
    @apply flex;
    @apply w-full;
  }

  &__ticks {
    @apply relative;
    @apply w-full;
  }

  &__ticks div {
    @apply relative;
    @apply border-t border-dotted;

    &:last-child {
      @apply border-b;

      border-bottom-style: solid;
    }
  }

  &__ticks div p {
    @apply absolute left-0 mt-1;
    @apply text-gray-500;
    @apply text-right;
    @apply whitespace-nowrap;

    font-size: 0.7rem;
  }

  &__chart {
    @apply absolute;
    @apply top-0 bottom-0 right-0;
    @apply flex justify-evenly;

    left: 5rem;
  }

  &__section-label {
    @apply text-gray-500;
    @apply mb-2;

    font-size: 0.7rem;
  }
}
