.c-receivables_form {
  @apply text-sm;

  &__items {
    @apply mt-8;

    thead tr th,
    tbody tr td {
      @apply border-b;
      @apply py-2;

      &:first-child {
        @apply pl-2;
      }

      &:last-child {
        @apply pr-2;
      }

      &:not(:first-child) {
        @apply pl-6;
      }
    }

    thead tr th {
      @apply text-sm;
      @apply bg-gray-100;
    }

    tbody tr td {
      @apply align-top;
      @apply text-sm;

      &:nth-child(2) {
        @apply w-auto;

        min-width: 200px;
      }

      &:nth-child(3),
      &:nth-child(4) {
        input {
          @apply text-right;
        }
      }
    }
  }
}
