table.c-receivables_form_summary {
  @apply table-fixed;
  @apply w-auto;
  @apply mt-4;

  tr td {
    @apply py-1;
  }

  tr td:first-child {
    @apply text-right;
    @apply pr-8;
  }

  tr td:last-child {
    @apply text-right;
    @apply font-semibold;

    min-width: 6.25rem;
  }
}
