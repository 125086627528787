:root {
  --max-width: 1200px;
}

.translation_missing {
  @apply text-red-500;
}

h1 {
  @apply text-3xl;
  @apply leading-9;
  @apply font-extrabold;
  @apply text-gray-900;

  @screen sm {
    @apply text-4xl;
    @apply leading-10;
  }
}

h2 {
  @apply text-2xl;
  @apply font-bold;
  @apply leading-tight;
  @apply text-gray-900;
}

.flatpickr-input.flatpickr-mobile {
  height: auto !important;
}
