.c-receivable {
  &__logo img {
    image-rendering: -webkit-optimize-contrast; /* fix resize bluriness */

    &.size-small {
      max-width: 200px;
      max-height: 80px;
    }

    &.size-medium {
      max-width: 250px;
      max-height: 100px;
    }

    &.size-large {
      max-width: 300px;
      max-height: 120px;
    }

    &.size-extra_large {
      max-width: 350px;
      max-height: 140px;
    }
  }

  &__meta {
    @apply w-auto;

    tr td:first-child {
      @apply pr-2;
      @apply w-0;
      @apply whitespace-nowrap;
    }

    tr td:last-child {
      @apply pl-2;

    }
  }

  &__items {
    thead,
    tbody,
    tfoot {
      tr td,
      tr th {
        @apply py-2 px-2;
        @apply text-right;
        @apply align-top;

        &:first-child {
          @apply text-left;
          @apply pl-0;
        }

        &:last-child {
          @apply font-semibold;
          @apply pr-0;
        }
      }
    }

    thead th {
      @apply border-b-2 border-gray-600;
    }

    tbody tr {
      td {
        @apply border-b border-gray-300;

        &:first-child {
          @apply w-full;

          @screen lg {
            @apply pr-28 !important;
          }
        }

        &:not(:first-child) {
          @apply pl-10 !important;
          @apply whitespace-nowrap;
        }

        &:last-child {
          @apply pl-15 !important;
        }

        p:last-child {
          @apply mb-0 !important;
        }
      }
    }

    tfoot tr {
      &:first-child td {
        @apply pt-8 !important;
      }

      &:last-child td {
        @apply pt-4 !important;
        @apply text-lg font-semibold;
      }

      td {
        @apply py-1 !important;
        @apply whitespace-nowrap;
      }

      td:first-child {
        @apply text-right !important;
      }

      td:last-child {
        @apply text-right !important;
      }
    }
  }
}
